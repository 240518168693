/* Auto-generated file */
.Icon_18-plus_outline::before
{
  background-image: url(icons/18-plus_outline.png);
}
.Icon_18-plus_outline_dim::before
{
  background-image: url(icons/18-plus_outline_dim.png);
}
.Icon_3d-glasses_regular::before
{
  background-image: url(icons/3d-glasses_regular.png);
}
.Icon_3d-glasses_regular_dim::before
{
  background-image: url(icons/3d-glasses_regular_dim.png);
}
.Icon_account_filled_dim::before
{
  background-image: url(icons/account_filled_dim.png);
}
.Icon_account_outline::before
{
  background-image: url(icons/account_outline.png);
}
.Icon_account_outline_dim::before
{
  background-image: url(icons/account_outline_dim.png);
}
.Icon_accounting_outline::before
{
  background-image: url(icons/accounting_outline.png);
}
.Icon_accounting_outline_dim::before
{
  background-image: url(icons/accounting_outline_dim.png);
}
.Icon_add_outline::before
{
  background-image: url(icons/add_outline.png);
}
.Icon_add_outline_dim::before
{
  background-image: url(icons/add_outline_dim.png);
}
.Icon_address_outline::before
{
  background-image: url(icons/address_outline.png);
}
.Icon_address_outline_dim::before
{
  background-image: url(icons/address_outline_dim.png);
}
.Icon_airport_outline::before
{
  background-image: url(icons/airport_outline.png);
}
.Icon_airport_outline_dim::before
{
  background-image: url(icons/airport_outline_dim.png);
}
.Icon_alligator_outline::before
{
  background-image: url(icons/alligator_outline.png);
}
.Icon_alligator_outline_dim::before
{
  background-image: url(icons/alligator_outline_dim.png);
}
.Icon_apartment_outline::before
{
  background-image: url(icons/apartment_outline.png);
}
.Icon_apartment_outline_dim::before
{
  background-image: url(icons/apartment_outline_dim.png);
}
.Icon_archeology_outline::before
{
  background-image: url(icons/archeology_outline.png);
}
.Icon_archeology_outline_dim::before
{
  background-image: url(icons/archeology_outline_dim.png);
}
.Icon_attention_filled::before
{
  background-image: url(icons/attention_filled.png);
}
.Icon_automatic-car-wash_outline::before
{
  background-image: url(icons/automatic-car-wash_outline.png);
}
.Icon_automatic-car-wash_outline_dim::before
{
  background-image: url(icons/automatic-car-wash_outline_dim.png);
}
.Icon_baby-face_outline::before
{
  background-image: url(icons/baby-face_outline.png);
}
.Icon_bakery_outline::before
{
  background-image: url(icons/bakery_outline.png);
}
.Icon_bakery_outline_dim::before
{
  background-image: url(icons/bakery_outline_dim.png);
}
.Icon_bank-building_outline::before
{
  background-image: url(icons/bank-building_outline.png);
}
.Icon_bank-building_outline_dim::before
{
  background-image: url(icons/bank-building_outline_dim.png);
}
.Icon_barbershop_outline::before
{
  background-image: url(icons/barbershop_outline.png);
}
.Icon_barbershop_outline_dim::before
{
  background-image: url(icons/barbershop_outline_dim.png);
}
.Icon_basketball-field_outline::before
{
  background-image: url(icons/basketball-field_outline.png);
}
.Icon_basketball-field_outline_dim::before
{
  background-image: url(icons/basketball-field_outline_dim.png);
}
.Icon_beach-umbrella_outline::before
{
  background-image: url(icons/beach-umbrella_outline.png);
}
.Icon_beach-umbrella_outline_dim::before
{
  background-image: url(icons/beach-umbrella_outline_dim.png);
}
.Icon_beer_outline::before
{
  background-image: url(icons/beer_outline.png);
}
.Icon_bicycle_outline::before
{
  background-image: url(icons/bicycle_outline.png);
}
.Icon_bicycle_outline_dim::before
{
  background-image: url(icons/bicycle_outline_dim.png);
}
.Icon_bin_outline::before
{
  background-image: url(icons/bin_outline.png);
}
.Icon_bin_outline_dim::before
{
  background-image: url(icons/bin_outline_dim.png);
}
.Icon_book_outline::before
{
  background-image: url(icons/book_outline.png);
}
.Icon_book_outline_dim::before
{
  background-image: url(icons/book_outline_dim.png);
}
.Icon_bowling_outline::before
{
  background-image: url(icons/bowling_outline.png);
}
.Icon_bowling_outline_dim::before
{
  background-image: url(icons/bowling_outline_dim.png);
}
.Icon_breakfast_outline::before
{
  background-image: url(icons/breakfast_outline.png);
}
.Icon_briefcase_custom::before
{
  background-image: url(icons/briefcase_custom.png);
}
.Icon_briefcase_outline::before
{
  background-image: url(icons/briefcase_outline.png);
}
.Icon_buffet-breakfast_outline::before
{
  background-image: url(icons/buffet-breakfast_outline.png);
}
.Icon_buffet-breakfast_outline_dim::before
{
  background-image: url(icons/buffet-breakfast_outline_dim.png);
}
.Icon_bus_outline::before
{
  background-image: url(icons/bus_outline.png);
}
.Icon_bus_outline_dim::before
{
  background-image: url(icons/bus_outline_dim.png);
}
.Icon_cab-stand_outline::before
{
  background-image: url(icons/cab-stand_outline.png);
}
.Icon_cab-stand_outline_dim::before
{
  background-image: url(icons/cab-stand_outline_dim.png);
}
.Icon_calendar_custom_white::before
{
  background-image: url(icons/calendar_custom_white.png);
}
.Icon_calendar_outline_white::before
{
  background-image: url(icons/calendar_outline_white.png);
}
.Icon_camper_outline::before
{
  background-image: url(icons/camper_outline.png);
}
.Icon_camper_outline_dim::before
{
  background-image: url(icons/camper_outline_dim.png);
}
.Icon_camping-tent_outline::before
{
  background-image: url(icons/camping-tent_outline.png);
}
.Icon_camping-tent_outline_dim::before
{
  background-image: url(icons/camping-tent_outline_dim.png);
}
.Icon_car-dealership-building_outline::before
{
  background-image: url(icons/car-dealership-building_outline.png);
}
.Icon_car-dealership-building_outline_dim::before
{
  background-image: url(icons/car-dealership-building_outline_dim.png);
}
.Icon_car-rental_outline::before
{
  background-image: url(icons/car-rental_outline.png);
}
.Icon_car-rental_outline_dim::before
{
  background-image: url(icons/car-rental_outline_dim.png);
}
.Icon_car-service_outline::before
{
  background-image: url(icons/car-service_outline.png);
}
.Icon_car-service_outline_dim::before
{
  background-image: url(icons/car-service_outline_dim.png);
}
.Icon_car_outline::before
{
  background-image: url(icons/car_outline.png);
}
.Icon_car_outline_dim::before
{
  background-image: url(icons/car_outline_dim.png);
}
.Icon_card_outline::before
{
  background-image: url(icons/card_outline.png);
}
.Icon_cardboard-box_outline::before
{
  background-image: url(icons/cardboard-box_outline.png);
}
.Icon_cardboard-box_outline_dim::before
{
  background-image: url(icons/cardboard-box_outline_dim.png);
}
.Icon_cash_outline::before
{
  background-image: url(icons/cash_outline.png);
}
.Icon_casino_outline::before
{
  background-image: url(icons/casino_outline.png);
}
.Icon_casino_outline_dim::before
{
  background-image: url(icons/casino_outline_dim.png);
}
.Icon_cave_outline::before
{
  background-image: url(icons/cave_outline.png);
}
.Icon_cave_outline_dim::before
{
  background-image: url(icons/cave_outline_dim.png);
}
.Icon_cemetery_outline::before
{
  background-image: url(icons/cemetery_outline.png);
}
.Icon_cemetery_outline_dim::before
{
  background-image: url(icons/cemetery_outline_dim.png);
}
.Icon_church_outline::before
{
  background-image: url(icons/church_outline.png);
}
.Icon_church_outline_dim::before
{
  background-image: url(icons/church_outline_dim.png);
}
.Icon_city-buildings_outline::before
{
  background-image: url(icons/city-buildings_outline.png);
}
.Icon_city-buildings_outline_dim::before
{
  background-image: url(icons/city-buildings_outline_dim.png);
}
.Icon_city-hall_outline::before
{
  background-image: url(icons/city-hall_outline.png);
}
.Icon_city-hall_outline_dim::before
{
  background-image: url(icons/city-hall_outline_dim.png);
}
.Icon_city-railway-station_outline::before
{
  background-image: url(icons/city-railway-station_outline.png);
}
.Icon_city-railway-station_outline_dim::before
{
  background-image: url(icons/city-railway-station_outline_dim.png);
}
.Icon_climber_outline::before
{
  background-image: url(icons/climber_outline.png);
}
.Icon_climber_outline_dim::before
{
  background-image: url(icons/climber_outline_dim.png);
}
.Icon_clock_outline_attention::before
{
  background-image: url(icons/clock_outline_attention.png);
}
.Icon_clock_outline_success::before
{
  background-image: url(icons/clock_outline_success.png);
}
.Icon_close_filled::before
{
  background-image: url(icons/close_filled.png);
}
.Icon_close_outline::before
{
  background-image: url(icons/close_outline.png);
}
.Icon_close_outline_black::before
{
  background-image: url(icons/close_outline_black.png);
}
.Icon_clothes-in-laundry_outline::before
{
  background-image: url(icons/clothes-in-laundry_outline.png);
}
.Icon_clothes-in-laundry_outline_dim::before
{
  background-image: url(icons/clothes-in-laundry_outline_dim.png);
}
.Icon_clothes_outline::before
{
  background-image: url(icons/clothes_outline.png);
}
.Icon_clothes_outline_dim::before
{
  background-image: url(icons/clothes_outline_dim.png);
}
.Icon_cocktail_outline::before
{
  background-image: url(icons/cocktail_outline.png);
}
.Icon_cocktail_outline_dim::before
{
  background-image: url(icons/cocktail_outline_dim.png);
}
.Icon_coffee-cup_outline::before
{
  background-image: url(icons/coffee-cup_outline.png);
}
.Icon_coffee-cup_outline_dim::before
{
  background-image: url(icons/coffee-cup_outline_dim.png);
}
.Icon_coffin-face_outline::before
{
  background-image: url(icons/coffin-face_outline.png);
}
.Icon_coffin-face_outline_dim::before
{
  background-image: url(icons/coffin-face_outline_dim.png);
}
.Icon_collect_outline::before
{
  background-image: url(icons/collect_outline.png);
}
.Icon_collect_outline_dim::before
{
  background-image: url(icons/collect_outline_dim.png);
}
.Icon_combi-ticket_outline::before
{
  background-image: url(icons/combi-ticket_outline.png);
}
.Icon_combi-ticket_outline_dim::before
{
  background-image: url(icons/combi-ticket_outline_dim.png);
}
.Icon_comedy_outline::before
{
  background-image: url(icons/comedy_outline.png);
}
.Icon_comedy_outline_dim::before
{
  background-image: url(icons/comedy_outline_dim.png);
}
.Icon_community_outline::before
{
  background-image: url(icons/community_outline.png);
}
.Icon_concert_outline::before
{
  background-image: url(icons/concert_outline.png);
}
.Icon_concert_outline_dim::before
{
  background-image: url(icons/concert_outline_dim.png);
}
.Icon_confetti_outline::before
{
  background-image: url(icons/confetti_outline.png);
}
.Icon_confetti_outline_dim::before
{
  background-image: url(icons/confetti_outline_dim.png);
}
.Icon_country-house_outline::before
{
  background-image: url(icons/country-house_outline.png);
}
.Icon_country-house_outline_dim::before
{
  background-image: url(icons/country-house_outline_dim.png);
}
.Icon_courthouse_outline::before
{
  background-image: url(icons/courthouse_outline.png);
}
.Icon_courthouse_outline_dim::before
{
  background-image: url(icons/courthouse_outline_dim.png);
}
.Icon_croissant_outline::before
{
  background-image: url(icons/croissant_outline.png);
}
.Icon_crown_filled::before
{
  background-image: url(icons/crown_filled.png);
}
.Icon_cycling_outline::before
{
  background-image: url(icons/cycling_outline.png);
}
.Icon_cycling_outline_dim::before
{
  background-image: url(icons/cycling_outline_dim.png);
}
.Icon_deadlift_outline::before
{
  background-image: url(icons/deadlift_outline.png);
}
.Icon_deadlift_outline_dim::before
{
  background-image: url(icons/deadlift_outline_dim.png);
}
.Icon_deliver-food_outline::before
{
  background-image: url(icons/deliver-food_outline.png);
}
.Icon_deliver-food_outline_dim::before
{
  background-image: url(icons/deliver-food_outline_dim.png);
}
.Icon_delivery_outline::before
{
  background-image: url(icons/delivery_outline.png);
}
.Icon_department-shop_outline::before
{
  background-image: url(icons/department-shop_outline.png);
}
.Icon_department-shop_outline_dim::before
{
  background-image: url(icons/department-shop_outline_dim.png);
}
.Icon_department_outline::before
{
  background-image: url(icons/department_outline.png);
}
.Icon_department_outline_dim::before
{
  background-image: url(icons/department_outline_dim.png);
}
.Icon_device-shop_outline::before
{
  background-image: url(icons/device-shop_outline.png);
}
.Icon_device-shop_outline_dim::before
{
  background-image: url(icons/device-shop_outline_dim.png);
}
.Icon_dinner_outline::before
{
  background-image: url(icons/dinner_outline.png);
}
.Icon_doctors-bag_regular::before
{
  background-image: url(icons/doctors-bag_regular.png);
}
.Icon_doctors-bag_regular_dim::before
{
  background-image: url(icons/doctors-bag_regular_dim.png);
}
.Icon_dog-paw_outline::before
{
  background-image: url(icons/dog-paw_outline.png);
}
.Icon_dog-paw_outline_dim::before
{
  background-image: url(icons/dog-paw_outline_dim.png);
}
.Icon_dog_outline::before
{
  background-image: url(icons/dog_outline.png);
}
.Icon_done_bold::before
{
  background-image: url(icons/done_bold.png);
}
.Icon_done_outline_black::before
{
  background-image: url(icons/done_outline_black.png);
}
.Icon_earth-element_regular::before
{
  background-image: url(icons/earth-element_regular.png);
}
.Icon_earth-element_regular_dim::before
{
  background-image: url(icons/earth-element_regular_dim.png);
}
.Icon_earth-globe_outline::before
{
  background-image: url(icons/earth-globe_outline.png);
}
.Icon_earth-globe_outline_dim::before
{
  background-image: url(icons/earth-globe_outline_dim.png);
}
.Icon_edit_filled::before
{
  background-image: url(icons/edit_filled.png);
}
.Icon_edit_filled_dim::before
{
  background-image: url(icons/edit_filled_dim.png);
}
.Icon_electrical_outline::before
{
  background-image: url(icons/electrical_outline.png);
}
.Icon_electrical_outline_dim::before
{
  background-image: url(icons/electrical_outline_dim.png);
}
.Icon_elephant-circus_outline::before
{
  background-image: url(icons/elephant-circus_outline.png);
}
.Icon_elephant-circus_outline_dim::before
{
  background-image: url(icons/elephant-circus_outline_dim.png);
}
.Icon_embassy_outline::before
{
  background-image: url(icons/embassy_outline.png);
}
.Icon_embassy_outline_dim::before
{
  background-image: url(icons/embassy_outline_dim.png);
}
.Icon_engineer_outline::before
{
  background-image: url(icons/engineer_outline.png);
}
.Icon_engineer_outline_dim::before
{
  background-image: url(icons/engineer_outline_dim.png);
}
.Icon_envelope_outline::before
{
  background-image: url(icons/envelope_outline.png);
}
.Icon_envelope_outline_dim::before
{
  background-image: url(icons/envelope_outline_dim.png);
}
.Icon_equestrian_outline::before
{
  background-image: url(icons/equestrian_outline.png);
}
.Icon_equestrian_outline_dim::before
{
  background-image: url(icons/equestrian_outline_dim.png);
}
.Icon_ereader_outline::before
{
  background-image: url(icons/ereader_outline.png);
}
.Icon_ereader_outline_dim::before
{
  background-image: url(icons/ereader_outline_dim.png);
}
.Icon_exhibition_outline::before
{
  background-image: url(icons/exhibition_outline.png);
}
.Icon_exhibition_outline_dim::before
{
  background-image: url(icons/exhibition_outline_dim.png);
}
.Icon_expand-arrow_custom_black::before
{
  background-image: url(icons/expand-arrow_custom_black.png);
}
.Icon_facebook-circled_color::before
{
  background-image: url(icons/facebook-circled_color.png);
}
.Icon_fast-moving-consumer-goods_outline::before
{
  background-image: url(icons/fast-moving-consumer-goods_outline.png);
}
.Icon_fast-moving-consumer-goods_outline_dim::before
{
  background-image: url(icons/fast-moving-consumer-goods_outline_dim.png);
}
.Icon_film-reel_outline::before
{
  background-image: url(icons/film-reel_outline.png);
}
.Icon_film-reel_outline_dim::before
{
  background-image: url(icons/film-reel_outline_dim.png);
}
.Icon_fire-station_outline::before
{
  background-image: url(icons/fire-station_outline.png);
}
.Icon_fire-station_outline_dim::before
{
  background-image: url(icons/fire-station_outline_dim.png);
}
.Icon_food-truck_outline::before
{
  background-image: url(icons/food-truck_outline.png);
}
.Icon_food-truck_outline_dim::before
{
  background-image: url(icons/food-truck_outline_dim.png);
}
.Icon_food_regular::before
{
  background-image: url(icons/food_regular.png);
}
.Icon_food_regular_dim::before
{
  background-image: url(icons/food_regular_dim.png);
}
.Icon_footwear_outline::before
{
  background-image: url(icons/footwear_outline.png);
}
.Icon_footwear_outline_dim::before
{
  background-image: url(icons/footwear_outline_dim.png);
}
.Icon_forest_outline::before
{
  background-image: url(icons/forest_outline.png);
}
.Icon_forest_outline_dim::before
{
  background-image: url(icons/forest_outline_dim.png);
}
.Icon_fruit-bag_outline::before
{
  background-image: url(icons/fruit-bag_outline.png);
}
.Icon_fruit-bag_outline_dim::before
{
  background-image: url(icons/fruit-bag_outline_dim.png);
}
.Icon_furniture-store_outline::before
{
  background-image: url(icons/furniture-store_outline.png);
}
.Icon_furniture-store_outline_dim::before
{
  background-image: url(icons/furniture-store_outline_dim.png);
}
.Icon_game-controller_outline::before
{
  background-image: url(icons/game-controller_outline.png);
}
.Icon_game-controller_outline_dim::before
{
  background-image: url(icons/game-controller_outline_dim.png);
}
.Icon_gas-station_outline::before
{
  background-image: url(icons/gas-station_outline.png);
}
.Icon_gas-station_outline_dim::before
{
  background-image: url(icons/gas-station_outline_dim.png);
}
.Icon_geography-document_outline::before
{
  background-image: url(icons/geography-document_outline.png);
}
.Icon_geography-document_outline_dim::before
{
  background-image: url(icons/geography-document_outline_dim.png);
}
.Icon_google-mobile_outline::before
{
  background-image: url(icons/google-mobile_outline.png);
}
.Icon_google-mobile_outline_dim::before
{
  background-image: url(icons/google-mobile_outline_dim.png);
}
.Icon_google_color::before
{
  background-image: url(icons/google_color.png);
}
.Icon_gps_outline::before
{
  background-image: url(icons/gps_outline.png);
}
.Icon_gps_outline_dim::before
{
  background-image: url(icons/gps_outline_dim.png);
}
.Icon_gps_outline_white::before
{
  background-image: url(icons/gps_outline_white.png);
}
.Icon_grocery-store_outline::before
{
  background-image: url(icons/grocery-store_outline.png);
}
.Icon_grocery-store_outline_dim::before
{
  background-image: url(icons/grocery-store_outline_dim.png);
}
.Icon_hand-box_outline::before
{
  background-image: url(icons/hand-box_outline.png);
}
.Icon_hand-planting_outline::before
{
  background-image: url(icons/hand-planting_outline.png);
}
.Icon_hand-planting_outline_dim::before
{
  background-image: url(icons/hand-planting_outline_dim.png);
}
.Icon_heavy_outline::before
{
  background-image: url(icons/heavy_outline.png);
}
.Icon_heavy_outline_dim::before
{
  background-image: url(icons/heavy_outline_dim.png);
}
.Icon_hindu-swastik_outline::before
{
  background-image: url(icons/hindu-swastik_outline.png);
}
.Icon_hindu-swastik_outline_dim::before
{
  background-image: url(icons/hindu-swastik_outline_dim.png);
}
.Icon_hospital_outline::before
{
  background-image: url(icons/hospital_outline.png);
}
.Icon_hospital_outline_dim::before
{
  background-image: url(icons/hospital_outline_dim.png);
}
.Icon_hostel_outline::before
{
  background-image: url(icons/hostel_outline.png);
}
.Icon_hostel_outline_dim::before
{
  background-image: url(icons/hostel_outline_dim.png);
}
.Icon_house_outline::before
{
  background-image: url(icons/house_outline.png);
}
.Icon_house_outline_dim::before
{
  background-image: url(icons/house_outline_dim.png);
}
.Icon_image-gallery_outline::before
{
  background-image: url(icons/image-gallery_outline.png);
}
.Icon_image-gallery_outline_dim::before
{
  background-image: url(icons/image-gallery_outline_dim.png);
}
.Icon_important-note_outline::before
{
  background-image: url(icons/important-note_outline.png);
}
.Icon_important-note_outline_white::before
{
  background-image: url(icons/important-note_outline_white.png);
}
.Icon_insert-card_outline::before
{
  background-image: url(icons/insert-card_outline.png);
}
.Icon_insert-card_outline_dim::before
{
  background-image: url(icons/insert-card_outline_dim.png);
}
.Icon_insert-credit-card_outline::before
{
  background-image: url(icons/insert-credit-card_outline.png);
}
.Icon_insert-credit-card_outline_dim::before
{
  background-image: url(icons/insert-credit-card_outline_dim.png);
}
.Icon_instagram_color::before
{
  background-image: url(icons/instagram_color.png);
}
.Icon_insurance-agent_outline::before
{
  background-image: url(icons/insurance-agent_outline.png);
}
.Icon_insurance-agent_outline_dim::before
{
  background-image: url(icons/insurance-agent_outline_dim.png);
}
.Icon_internet_outline::before
{
  background-image: url(icons/internet_outline.png);
}
.Icon_internet_outline_dim::before
{
  background-image: url(icons/internet_outline_dim.png);
}
.Icon_investment_outline::before
{
  background-image: url(icons/investment_outline.png);
}
.Icon_investment_outline_dim::before
{
  background-image: url(icons/investment_outline_dim.png);
}
.Icon_jewelry_outline::before
{
  background-image: url(icons/jewelry_outline.png);
}
.Icon_jewelry_outline_dim::before
{
  background-image: url(icons/jewelry_outline_dim.png);
}
.Icon_kettle_outline::before
{
  background-image: url(icons/kettle_outline.png);
}
.Icon_kettle_outline_dim::before
{
  background-image: url(icons/kettle_outline_dim.png);
}
.Icon_lake_outline::before
{
  background-image: url(icons/lake_outline.png);
}
.Icon_lake_outline_dim::before
{
  background-image: url(icons/lake_outline_dim.png);
}
.Icon_language_outline::before
{
  background-image: url(icons/language_outline.png);
}
.Icon_language_outline_dim::before
{
  background-image: url(icons/language_outline_dim.png);
}
.Icon_law_outline::before
{
  background-image: url(icons/law_outline.png);
}
.Icon_law_outline_dim::before
{
  background-image: url(icons/law_outline_dim.png);
}
.Icon_left_filled::before
{
  background-image: url(icons/left_filled.png);
}
.Icon_left_outline::before
{
  background-image: url(icons/left_outline.png);
}
.Icon_left_outline_black::before
{
  background-image: url(icons/left_outline_black.png);
}
.Icon_left_outline_white::before
{
  background-image: url(icons/left_outline_white.png);
}
.Icon_library-building_outline::before
{
  background-image: url(icons/library-building_outline.png);
}
.Icon_library-building_outline_dim::before
{
  background-image: url(icons/library-building_outline_dim.png);
}
.Icon_lion-statue_outline::before
{
  background-image: url(icons/lion-statue_outline.png);
}
.Icon_lion-statue_outline_dim::before
{
  background-image: url(icons/lion-statue_outline_dim.png);
}
.Icon_liquor-shelf_outline::before
{
  background-image: url(icons/liquor-shelf_outline.png);
}
.Icon_liquor-shelf_outline_dim::before
{
  background-image: url(icons/liquor-shelf_outline_dim.png);
}
.Icon_location-off_outline::before
{
  background-image: url(icons/location-off_outline.png);
}
.Icon_location-off_outline_dim::before
{
  background-image: url(icons/location-off_outline_dim.png);
}
.Icon_location_filled::before
{
  background-image: url(icons/location_filled.png);
}
.Icon_location_filled_dim::before
{
  background-image: url(icons/location_filled_dim.png);
}
.Icon_location_outline::before
{
  background-image: url(icons/location_outline.png);
}
.Icon_location_outline_dim::before
{
  background-image: url(icons/location_outline_dim.png);
}
.Icon_logo-apple::before
{
  background-image: url(icons/logo-apple.png);
}
.Icon_logo-google-play-store::before
{
  background-image: url(icons/logo-google-play-store.png);
}
.Icon_logo-huawei::before
{
  background-image: url(icons/logo-huawei.png);
}
.Icon_logo-rustore::before
{
  background-image: url(icons/logo-rustore.png);
}
.Icon_logo-tiktok_color::before
{
  background-image: url(icons/logo-tiktok_color.png);
}
.Icon_logo-wechat_color::before
{
  background-image: url(icons/logo-wechat_color.png);
}
.Icon_lunch_outline::before
{
  background-image: url(icons/lunch_outline.png);
}
.Icon_magnetic-card_outline::before
{
  background-image: url(icons/magnetic-card_outline.png);
}
.Icon_magnetic-card_outline_dim::before
{
  background-image: url(icons/magnetic-card_outline_dim.png);
}
.Icon_makeup-brush_outline::before
{
  background-image: url(icons/makeup-brush_outline.png);
}
.Icon_makeup-brush_outline_dim::before
{
  background-image: url(icons/makeup-brush_outline_dim.png);
}
.Icon_map-marker_outline::before
{
  background-image: url(icons/map-marker_outline.png);
}
.Icon_map-marker_outline_dim::before
{
  background-image: url(icons/map-marker_outline_dim.png);
}
.Icon_map-marker_outline_white::before
{
  background-image: url(icons/map-marker_outline_white.png);
}
.Icon_massage_outline::before
{
  background-image: url(icons/massage_outline.png);
}
.Icon_massage_outline_dim::before
{
  background-image: url(icons/massage_outline_dim.png);
}
.Icon_menu-vertical_outline_black::before
{
  background-image: url(icons/menu-vertical_outline_black.png);
}
.Icon_menu_filled::before
{
  background-image: url(icons/menu_filled.png);
}
.Icon_message_filled::before
{
  background-image: url(icons/message_filled.png);
}
.Icon_message_outline::before
{
  background-image: url(icons/message_outline.png);
}
.Icon_minus-sign_outline::before
{
  background-image: url(icons/minus-sign_outline.png);
}
.Icon_minus-sign_outline_dim::before
{
  background-image: url(icons/minus-sign_outline_dim.png);
}
.Icon_more-info_outline::before
{
  background-image: url(icons/more-info_outline.png);
}
.Icon_mosque_outline::before
{
  background-image: url(icons/mosque_outline.png);
}
.Icon_mosque_outline_dim::before
{
  background-image: url(icons/mosque_outline_dim.png);
}
.Icon_mountain_outline::before
{
  background-image: url(icons/mountain_outline.png);
}
.Icon_mountain_outline_dim::before
{
  background-image: url(icons/mountain_outline_dim.png);
}
.Icon_movie_outline::before
{
  background-image: url(icons/movie_outline.png);
}
.Icon_movie_outline_dim::before
{
  background-image: url(icons/movie_outline_dim.png);
}
.Icon_museum_outline::before
{
  background-image: url(icons/museum_outline.png);
}
.Icon_museum_outline_dim::before
{
  background-image: url(icons/museum_outline_dim.png);
}
.Icon_music-conductor_regular::before
{
  background-image: url(icons/music-conductor_regular.png);
}
.Icon_music-conductor_regular_dim::before
{
  background-image: url(icons/music-conductor_regular_dim.png);
}
.Icon_my-location_outline::before
{
  background-image: url(icons/my-location_outline.png);
}
.Icon_my-location_outline_dim::before
{
  background-image: url(icons/my-location_outline_dim.png);
}
.Icon_national-park_outline::before
{
  background-image: url(icons/national-park_outline.png);
}
.Icon_national-park_outline_dim::before
{
  background-image: url(icons/national-park_outline_dim.png);
}
.Icon_natural-food_regular::before
{
  background-image: url(icons/natural-food_regular.png);
}
.Icon_natural-food_regular_dim::before
{
  background-image: url(icons/natural-food_regular_dim.png);
}
.Icon_nomadlocator_custom::before
{
  background-image: url(icons/nomadlocator_custom.png);
}
.Icon_north-direction_outline::before
{
  background-image: url(icons/north-direction_outline.png);
}
.Icon_north-direction_outline_dim::before
{
  background-image: url(icons/north-direction_outline_dim.png);
}
.Icon_notepad_outline_white::before
{
  background-image: url(icons/notepad_outline_white.png);
}
.Icon_occupied-bed_outline::before
{
  background-image: url(icons/occupied-bed_outline.png);
}
.Icon_occupied-bed_outline_dim::before
{
  background-image: url(icons/occupied-bed_outline_dim.png);
}
.Icon_occupied-bed_outline_white::before
{
  background-image: url(icons/occupied-bed_outline_white.png);
}
.Icon_odnoklassniki_color::before
{
  background-image: url(icons/odnoklassniki_color.png);
}
.Icon_online-support_outline::before
{
  background-image: url(icons/online-support_outline.png);
}
.Icon_organic-food_outline::before
{
  background-image: url(icons/organic-food_outline.png);
}
.Icon_organic-food_outline_dim::before
{
  background-image: url(icons/organic-food_outline_dim.png);
}
.Icon_pagoda_outline::before
{
  background-image: url(icons/pagoda_outline.png);
}
.Icon_pagoda_outline_dim::before
{
  background-image: url(icons/pagoda_outline_dim.png);
}
.Icon_paint-palette-with-brush_outline::before
{
  background-image: url(icons/paint-palette-with-brush_outline.png);
}
.Icon_paint-palette-with-brush_outline_dim::before
{
  background-image: url(icons/paint-palette-with-brush_outline_dim.png);
}
.Icon_panties_outline::before
{
  background-image: url(icons/panties_outline.png);
}
.Icon_panties_outline_dim::before
{
  background-image: url(icons/panties_outline_dim.png);
}
.Icon_parachute_outline::before
{
  background-image: url(icons/parachute_outline.png);
}
.Icon_parachute_outline_dim::before
{
  background-image: url(icons/parachute_outline_dim.png);
}
.Icon_parking-sign_outline::before
{
  background-image: url(icons/parking-sign_outline.png);
}
.Icon_parking_outline::before
{
  background-image: url(icons/parking_outline.png);
}
.Icon_parking_outline_dim::before
{
  background-image: url(icons/parking_outline_dim.png);
}
.Icon_party_outline::before
{
  background-image: url(icons/party_outline.png);
}
.Icon_party_outline_dim::before
{
  background-image: url(icons/party_outline_dim.png);
}
.Icon_passport-and-tickets_outline::before
{
  background-image: url(icons/passport-and-tickets_outline.png);
}
.Icon_pencil_outline::before
{
  background-image: url(icons/pencil_outline.png);
}
.Icon_pencil_outline_dim::before
{
  background-image: url(icons/pencil_outline_dim.png);
}
.Icon_pharmacy-shop_outline::before
{
  background-image: url(icons/pharmacy-shop_outline.png);
}
.Icon_pharmacy-shop_outline_dim::before
{
  background-image: url(icons/pharmacy-shop_outline_dim.png);
}
.Icon_phone_outline::before
{
  background-image: url(icons/phone_outline.png);
}
.Icon_phone_outline_dim::before
{
  background-image: url(icons/phone_outline_dim.png);
}
.Icon_phone_outline_white::before
{
  background-image: url(icons/phone_outline_white.png);
}
.Icon_physical-therapy_outline::before
{
  background-image: url(icons/physical-therapy_outline.png);
}
.Icon_physical-therapy_outline_dim::before
{
  background-image: url(icons/physical-therapy_outline_dim.png);
}
.Icon_plane_outline::before
{
  background-image: url(icons/plane_outline.png);
}
.Icon_plane_outline_white::before
{
  background-image: url(icons/plane_outline_white.png);
}
.Icon_planirus_custom::before
{
  background-image: url(icons/planirus_custom.png);
}
.Icon_plus_outline::before
{
  background-image: url(icons/plus_outline.png);
}
.Icon_plus_outline_dim::before
{
  background-image: url(icons/plus_outline_dim.png);
}
.Icon_plus_outline_white::before
{
  background-image: url(icons/plus_outline_white.png);
}
.Icon_point-of-interest_outline::before
{
  background-image: url(icons/point-of-interest_outline.png);
}
.Icon_point-of-interest_outline_dim::before
{
  background-image: url(icons/point-of-interest_outline_dim.png);
}
.Icon_police-station_outline::before
{
  background-image: url(icons/police-station_outline.png);
}
.Icon_police-station_outline_dim::before
{
  background-image: url(icons/police-station_outline_dim.png);
}
.Icon_porsche-911-new_outline::before
{
  background-image: url(icons/porsche-911-new_outline.png);
}
.Icon_porsche-911-new_outline_dim::before
{
  background-image: url(icons/porsche-911-new_outline_dim.png);
}
.Icon_post-box_outline::before
{
  background-image: url(icons/post-box_outline.png);
}
.Icon_post-box_outline_dim::before
{
  background-image: url(icons/post-box_outline_dim.png);
}
.Icon_post-office_outline::before
{
  background-image: url(icons/post-office_outline.png);
}
.Icon_post-office_outline_dim::before
{
  background-image: url(icons/post-office_outline_dim.png);
}
.Icon_pray_outline::before
{
  background-image: url(icons/pray_outline.png);
}
.Icon_pray_outline_dim::before
{
  background-image: url(icons/pray_outline_dim.png);
}
.Icon_preschool_outline::before
{
  background-image: url(icons/preschool_outline.png);
}
.Icon_preschool_outline_dim::before
{
  background-image: url(icons/preschool_outline_dim.png);
}
.Icon_previous-location_custom::before
{
  background-image: url(icons/previous-location_custom.png);
}
.Icon_previous-location_custom_dim::before
{
  background-image: url(icons/previous-location_custom_dim.png);
}
.Icon_protect_outline::before
{
  background-image: url(icons/protect_outline.png);
}
.Icon_public-speaking_outline::before
{
  background-image: url(icons/public-speaking_outline.png);
}
.Icon_public-speaking_outline_dim::before
{
  background-image: url(icons/public-speaking_outline_dim.png);
}
.Icon_public-transportation_outline::before
{
  background-image: url(icons/public-transportation_outline.png);
}
.Icon_public-transportation_outline_dim::before
{
  background-image: url(icons/public-transportation_outline_dim.png);
}
.Icon_railway-station_outline::before
{
  background-image: url(icons/railway-station_outline.png);
}
.Icon_railway-station_outline_dim::before
{
  background-image: url(icons/railway-station_outline_dim.png);
}
.Icon_real-estate-agent_outline::before
{
  background-image: url(icons/real-estate-agent_outline.png);
}
.Icon_real-estate-agent_outline_dim::before
{
  background-image: url(icons/real-estate-agent_outline_dim.png);
}
.Icon_rectangular-aquarium_outline::before
{
  background-image: url(icons/rectangular-aquarium_outline.png);
}
.Icon_rectangular-aquarium_outline_dim::before
{
  background-image: url(icons/rectangular-aquarium_outline_dim.png);
}
.Icon_reserve_outline::before
{
  background-image: url(icons/reserve_outline.png);
}
.Icon_restaurant-building_outline::before
{
  background-image: url(icons/restaurant-building_outline.png);
}
.Icon_restaurant-building_outline_dim::before
{
  background-image: url(icons/restaurant-building_outline_dim.png);
}
.Icon_restaurant-table_outline::before
{
  background-image: url(icons/restaurant-table_outline.png);
}
.Icon_restaurant_outline::before
{
  background-image: url(icons/restaurant_outline.png);
}
.Icon_restaurant_outline_dim::before
{
  background-image: url(icons/restaurant_outline_dim.png);
}
.Icon_restore-down_custom::before
{
  background-image: url(icons/restore-down_custom.png);
}
.Icon_restore-down_outline::before
{
  background-image: url(icons/restore-down_outline.png);
}
.Icon_restore-down_outline_dim::before
{
  background-image: url(icons/restore-down_outline_dim.png);
}
.Icon_right_outline_black::before
{
  background-image: url(icons/right_outline_black.png);
}
.Icon_roofing_outline::before
{
  background-image: url(icons/roofing_outline.png);
}
.Icon_roofing_outline_dim::before
{
  background-image: url(icons/roofing_outline_dim.png);
}
.Icon_room_outline::before
{
  background-image: url(icons/room_outline.png);
}
.Icon_room_outline_dim::before
{
  background-image: url(icons/room_outline_dim.png);
}
.Icon_rounded-square_outline::before
{
  background-image: url(icons/rounded-square_outline.png);
}
.Icon_rounded-square_outline_dim::before
{
  background-image: url(icons/rounded-square_outline_dim.png);
}
.Icon_route_outline::before
{
  background-image: url(icons/route_outline.png);
}
.Icon_route_outline_dim::before
{
  background-image: url(icons/route_outline_dim.png);
}
.Icon_route_outline_white::before
{
  background-image: url(icons/route_outline_white.png);
}
.Icon_sailboat_outline::before
{
  background-image: url(icons/sailboat_outline.png);
}
.Icon_sailboat_outline_dim::before
{
  background-image: url(icons/sailboat_outline_dim.png);
}
.Icon_salami-pizza_outline::before
{
  background-image: url(icons/salami-pizza_outline.png);
}
.Icon_salami-pizza_outline_dim::before
{
  background-image: url(icons/salami-pizza_outline_dim.png);
}
.Icon_school_outline::before
{
  background-image: url(icons/school_outline.png);
}
.Icon_school_outline_dim::before
{
  background-image: url(icons/school_outline_dim.png);
}
.Icon_scooter_outline::before
{
  background-image: url(icons/scooter_outline.png);
}
.Icon_scooter_outline_dim::before
{
  background-image: url(icons/scooter_outline_dim.png);
}
.Icon_search_outline::before
{
  background-image: url(icons/search_outline.png);
}
.Icon_search_outline_dim::before
{
  background-image: url(icons/search_outline_dim.png);
}
.Icon_settings_outline::before
{
  background-image: url(icons/settings_outline.png);
}
.Icon_share_outline::before
{
  background-image: url(icons/share_outline.png);
}
.Icon_share_outline_dim::before
{
  background-image: url(icons/share_outline_dim.png);
}
.Icon_shopping-bag_regular::before
{
  background-image: url(icons/shopping-bag_regular.png);
}
.Icon_shopping-bag_regular_dim::before
{
  background-image: url(icons/shopping-bag_regular_dim.png);
}
.Icon_shopping-mall_outline::before
{
  background-image: url(icons/shopping-mall_outline.png);
}
.Icon_shopping-mall_outline_dim::before
{
  background-image: url(icons/shopping-mall_outline_dim.png);
}
.Icon_shuttle-bus_outline::before
{
  background-image: url(icons/shuttle-bus_outline.png);
}
.Icon_shuttle-bus_outline_dim::before
{
  background-image: url(icons/shuttle-bus_outline_dim.png);
}
.Icon_slr-camera_outline::before
{
  background-image: url(icons/slr-camera_outline.png);
}
.Icon_slr-camera_outline_dim::before
{
  background-image: url(icons/slr-camera_outline_dim.png);
}
.Icon_small-business_outline::before
{
  background-image: url(icons/small-business_outline.png);
}
.Icon_small-business_outline_dim::before
{
  background-image: url(icons/small-business_outline_dim.png);
}
.Icon_soccer_regular::before
{
  background-image: url(icons/soccer_regular.png);
}
.Icon_soccer_regular_dim::before
{
  background-image: url(icons/soccer_regular_dim.png);
}
.Icon_spa_outline::before
{
  background-image: url(icons/spa_outline.png);
}
.Icon_spa_outline_dim::before
{
  background-image: url(icons/spa_outline_dim.png);
}
.Icon_sports-fishing_outline::before
{
  background-image: url(icons/sports-fishing_outline.png);
}
.Icon_sports-fishing_outline_dim::before
{
  background-image: url(icons/sports-fishing_outline_dim.png);
}
.Icon_stadium_outline::before
{
  background-image: url(icons/stadium_outline.png);
}
.Icon_stadium_outline_dim::before
{
  background-image: url(icons/stadium_outline_dim.png);
}
.Icon_star_filled::before
{
  background-image: url(icons/star_filled.png);
}
.Icon_star_outline::before
{
  background-image: url(icons/star_outline.png);
}
.Icon_star_outline_dim::before
{
  background-image: url(icons/star_outline_dim.png);
}
.Icon_star_outline_white::before
{
  background-image: url(icons/star_outline_white.png);
}
.Icon_steam-engine_outline::before
{
  background-image: url(icons/steam-engine_outline.png);
}
.Icon_steam-engine_outline_dim::before
{
  background-image: url(icons/steam-engine_outline_dim.png);
}
.Icon_storage_outline::before
{
  background-image: url(icons/storage_outline.png);
}
.Icon_storage_outline_dim::before
{
  background-image: url(icons/storage_outline_dim.png);
}
.Icon_suitcase_filled_white::before
{
  background-image: url(icons/suitcase_filled_white.png);
}
.Icon_suitcase_outline::before
{
  background-image: url(icons/suitcase_outline.png);
}
.Icon_suitcase_outline_dim::before
{
  background-image: url(icons/suitcase_outline_dim.png);
}
.Icon_suv_outline::before
{
  background-image: url(icons/suv_outline.png);
}
.Icon_suv_outline_dim::before
{
  background-image: url(icons/suv_outline_dim.png);
}
.Icon_swimming_outline::before
{
  background-image: url(icons/swimming_outline.png);
}
.Icon_swimming_outline_dim::before
{
  background-image: url(icons/swimming_outline_dim.png);
}
.Icon_swing_outline::before
{
  background-image: url(icons/swing_outline.png);
}
.Icon_swing_outline_dim::before
{
  background-image: url(icons/swing_outline_dim.png);
}
.Icon_synagogue_outline::before
{
  background-image: url(icons/synagogue_outline.png);
}
.Icon_synagogue_outline_dim::before
{
  background-image: url(icons/synagogue_outline_dim.png);
}
.Icon_tags_outline::before
{
  background-image: url(icons/tags_outline.png);
}
.Icon_take-away-food_outline::before
{
  background-image: url(icons/take-away-food_outline.png);
}
.Icon_take-away-food_outline_dim::before
{
  background-image: url(icons/take-away-food_outline_dim.png);
}
.Icon_taxi_outline::before
{
  background-image: url(icons/taxi_outline.png);
}
.Icon_taxi_outline_white::before
{
  background-image: url(icons/taxi_outline_white.png);
}
.Icon_tea-pair_outline::before
{
  background-image: url(icons/tea-pair_outline.png);
}
.Icon_tea-pair_outline_dim::before
{
  background-image: url(icons/tea-pair_outline_dim.png);
}
.Icon_telegram_custom::before
{
  background-image: url(icons/telegram_custom.png);
}
.Icon_theatre-mask_outline::before
{
  background-image: url(icons/theatre-mask_outline.png);
}
.Icon_theatre-mask_outline_dim::before
{
  background-image: url(icons/theatre-mask_outline_dim.png);
}
.Icon_theme-park_outline::before
{
  background-image: url(icons/theme-park_outline.png);
}
.Icon_theme-park_outline_dim::before
{
  background-image: url(icons/theme-park_outline_dim.png);
}
.Icon_timezone_outline::before
{
  background-image: url(icons/timezone_outline.png);
}
.Icon_tools_outline::before
{
  background-image: url(icons/tools_outline.png);
}
.Icon_tools_outline_dim::before
{
  background-image: url(icons/tools_outline_dim.png);
}
.Icon_tooth_outline::before
{
  background-image: url(icons/tooth_outline.png);
}
.Icon_tooth_outline_dim::before
{
  background-image: url(icons/tooth_outline_dim.png);
}
.Icon_trekking_outline::before
{
  background-image: url(icons/trekking_outline.png);
}
.Icon_trekking_outline_dim::before
{
  background-image: url(icons/trekking_outline_dim.png);
}
.Icon_tulip_outline::before
{
  background-image: url(icons/tulip_outline.png);
}
.Icon_tulip_outline_dim::before
{
  background-image: url(icons/tulip_outline_dim.png);
}
.Icon_tune_outline::before
{
  background-image: url(icons/tune_outline.png);
}
.Icon_tune_outline_white::before
{
  background-image: url(icons/tune_outline_white.png);
}
.Icon_twitter-circled_color::before
{
  background-image: url(icons/twitter-circled_color.png);
}
.Icon_two-tickets_outline::before
{
  background-image: url(icons/two-tickets_outline.png);
}
.Icon_two-tickets_outline_white::before
{
  background-image: url(icons/two-tickets_outline_white.png);
}
.Icon_underground_outline::before
{
  background-image: url(icons/underground_outline.png);
}
.Icon_underground_outline_dim::before
{
  background-image: url(icons/underground_outline_dim.png);
}
.Icon_university_outline::before
{
  background-image: url(icons/university_outline.png);
}
.Icon_university_outline_dim::before
{
  background-image: url(icons/university_outline_dim.png);
}
.Icon_unsplash_metro_dim::before
{
  background-image: url(icons/unsplash_metro_dim.png);
}
.Icon_user-groups_outline::before
{
  background-image: url(icons/user-groups_outline.png);
}
.Icon_user-groups_outline_dim::before
{
  background-image: url(icons/user-groups_outline_dim.png);
}
.Icon_user-manual_outline::before
{
  background-image: url(icons/user-manual_outline.png);
}
.Icon_user-manual_outline_dim::before
{
  background-image: url(icons/user-manual_outline_dim.png);
}
.Icon_veterinarian-male_outline::before
{
  background-image: url(icons/veterinarian-male_outline.png);
}
.Icon_veterinarian-male_outline_dim::before
{
  background-image: url(icons/veterinarian-male_outline_dim.png);
}
.Icon_vintage-camera_regular::before
{
  background-image: url(icons/vintage-camera_regular.png);
}
.Icon_vintage-camera_regular_dim::before
{
  background-image: url(icons/vintage-camera_regular_dim.png);
}
.Icon_vk-circled_color::before
{
  background-image: url(icons/vk-circled_color.png);
}
.Icon_vk_filled::before
{
  background-image: url(icons/vk_filled.png);
}
.Icon_volcano_outline::before
{
  background-image: url(icons/volcano_outline.png);
}
.Icon_volcano_outline_dim::before
{
  background-image: url(icons/volcano_outline_dim.png);
}
.Icon_walking_outline::before
{
  background-image: url(icons/walking_outline.png);
}
.Icon_walking_outline_dim::before
{
  background-image: url(icons/walking_outline_dim.png);
}
.Icon_water-park_outline::before
{
  background-image: url(icons/water-park_outline.png);
}
.Icon_water-park_outline_dim::before
{
  background-image: url(icons/water-park_outline_dim.png);
}
.Icon_water-pipe_outline::before
{
  background-image: url(icons/water-pipe_outline.png);
}
.Icon_water-pipe_outline_dim::before
{
  background-image: url(icons/water-pipe_outline_dim.png);
}
.Icon_water-transportation_outline::before
{
  background-image: url(icons/water-transportation_outline.png);
}
.Icon_waterfall_outline::before
{
  background-image: url(icons/waterfall_outline.png);
}
.Icon_waterfall_outline_dim::before
{
  background-image: url(icons/waterfall_outline_dim.png);
}
.Icon_wheelchair_outline::before
{
  background-image: url(icons/wheelchair_outline.png);
}
.Icon_wheelchair_outline_dim::before
{
  background-image: url(icons/wheelchair_outline_dim.png);
}
.Icon_wifi_outline::before
{
  background-image: url(icons/wifi_outline.png);
}
.Icon_wine-glass_outline::before
{
  background-image: url(icons/wine-glass_outline.png);
}
.Icon_wrench_outline::before
{
  background-image: url(icons/wrench_outline.png);
}
.Icon_wrench_outline_dim::before
{
  background-image: url(icons/wrench_outline_dim.png);
}
.Icon_www_outline::before
{
  background-image: url(icons/www_outline.png);
}
.Icon_yoga_outline::before
{
  background-image: url(icons/yoga_outline.png);
}
.Icon_yoga_outline_dim::before
{
  background-image: url(icons/yoga_outline_dim.png);
}
.Icon_youtube_custom::before
{
  background-image: url(icons/youtube_custom.png);
}
